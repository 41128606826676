import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getUrlInGeneric } from "../helpers/url";

import { setShowErrorModal } from "./preferenceSlice";

import {
  IMappingItem,
  IMappingSlice,
  IFetchStatus,
} from "../types/mappingTypes";

const initialState: IMappingSlice = {
  mapping: {
    loading: false,
    defaultMappings: {},
    error: "",
    currentRequestId: null,
  },
  isMappingSaved: {},
  currentlyEditing: null,
  selectedPreference: null,
  searchInput: "",
  searchFilteredArray: [],
  isEditMapping: false,
  deletedTriggerFields: {},
  // Pagination
  itemOffset: 0,
  slicedMappings: [],
  pageCount: 0,
  selectedIndex: 0,
  activeAccordionTitle: {},
};

interface IFields {
  HRF: string;
  CRF: string | number;
  type: string;
  description: string;
  required?: boolean;
  accordion?: string;
}

interface IFetchStatusProps {
  userIds: {
    [k: string]: string | number;
  };
  accordionFor?: {
    [k: string]: boolean;
  };
  fields?: {
    [key: string]: IFields[];
  };
  fieldKeys?: string[];
  isSaved?: boolean;
}

/**
 * This function is used to fetch the status
 */
export const fetchStatus = createAsyncThunk(
  "mapping/fetchStatus",
  async (props: IFetchStatusProps, { dispatch }) => {
    try {
      const { userIds, accordionFor, fields, fieldKeys } = props;

      const res: AxiosResponse<IFetchStatus> = await axios.get<IFetchStatus>(
        getUrlInGeneric("REACT_APP_FETCH_STATUS"),
        {
          params: userIds,
        }
      );
      return { ...res.data, accordionFor, fields, fieldKeys };
    } catch (error) {
      console.log(error);
      let errorMessage;
      if (error.response && error.response.data.message)
        errorMessage = error.response.data.message;
      else errorMessage = error.message;
      dispatch(setShowErrorModal({ message: errorMessage }));
      throw error;
    }
  }
);

export const mappingSlice = createSlice({
  name: "mapping",
  initialState,
  reducers: {
    /**
     * This function is used to update the page count
     * @param state - Mapping slice
     * @param payload - Page count
     */
    updatePageCount(state, { payload }) {
      state.pageCount = payload.count;
    },
    /**
     * This function is used to update itemOffset
     * @param state - Mapping slice
     * @param payload - Item offset
     */
    updateItemOffset(state, { payload }) {
      state.itemOffset = payload.itemOffset;
    },
    /**
     * This function is used to update the selected index
     * @param state - Mapping slice
     * @param payload - Selected index
     */
    updateSelectedIndex(state, { payload }) {
      state.selectedIndex = payload.selectedIndex;
    },
    /**
     * This function is used for updating custom mappings
     * @param state - Mapping slice
     * @param payload - Custom mappings
     */
    updateSlicedMappings(state, { payload }) {
      state.slicedMappings = payload.mappings;
    },
    /**
     * This function is used to store the active accordion key and when ever the active accordion key changes the search input, search filtered array, currently editing, isEditMapping and selected preference are cleared
     * @param state - Mapping slice
     * @param param1 - payload
     */
    storeActiveAccordion(state, { payload }) {
      const { mappingsFor, activeAccordion } = payload;
      state.activeAccordionTitle[mappingsFor] = activeAccordion;
      state.searchInput = "";
      state.searchFilteredArray = [];
      state.currentlyEditing = null;
      state.isEditMapping = false;
      state.selectedPreference = null;
    },
    /**
     * Reducer for clearing default values.
     * @param state - Mapping slice
     */
    clearDefaultValues(state) {
      state.searchInput = "";
      state.searchFilteredArray = [];
      state.currentlyEditing = null;
      state.isEditMapping = false;
      state.selectedPreference = null;
      state.selectedIndex = 0;
      state.itemOffset = 0;
      state.pageCount = 0;
    },
    /**
     * Reducer stores the currently editing mapping
     * @param state - Mapping slice
     * @param param1 - payload
     */
    onEditMapping(state, { payload }) {
      state.currentlyEditing = payload.id;
      state.isEditMapping = true;
    },
    /**
     * Reducer for updating isMappingSaved
     * @param state - Mapping slice
     * @param param1 - payload
     */
    updateIsMappingSaved(state, { payload }) {
      state.isMappingSaved[payload.mappingsFor] = true;
    },
    /**
     * Reducer helps to search the mappings present in the mapping window
     * @param state - Mapping slice
     * @param param1 - Payload
     */
    onSearchMappings(state, { payload }) {
      const { mappingsFor, searchString, isAccordion } = payload;

      state.searchInput = searchString;
      state.searchFilteredArray = [];
      if (state.searchInput !== "") {
        state.searchFilteredArray = (
          isAccordion
            ? state.mapping.defaultMappings[mappingsFor][
                state.activeAccordionTitle[mappingsFor]
              ]
            : state.mapping.defaultMappings[mappingsFor]
        )
          .filter(
            (mappedItem: IMappingItem) =>
              Object.values(mappedItem.triggerApp).some((fields) =>
                fields.some((field) =>
                  field.HRF.toLowerCase().includes(searchString.toLowerCase())
                )
              ) ||
              mappedItem.HRF.toLowerCase().includes(searchString.toLowerCase())
          )
          .map((mappedItem) => mappedItem.id);
      }
    },
    /**
     * Reducer deletes existing mappings
     * @param state - Mapping slice
     * @param param1 - Payload
     */
    onDeleteExistingMappings(state, { payload }) {
      const { mappingsFor, id, fieldKeys, isAccordion } = payload;

      // Update the mapping by removing the specified fields
      (isAccordion
        ? state.mapping.defaultMappings[mappingsFor][
            state.activeAccordionTitle[mappingsFor]
          ]
        : state.mapping.defaultMappings[mappingsFor]
      ).forEach((mappedItem) => {
        if (mappedItem.id === id) {
          fieldKeys.forEach((key) => {
            mappedItem.triggerApp[key] = [];
          });
        }
      });
    },
    /**
     * Reducer for saving edited or new mappings
     * @param state - Mapping Slice
     * @param param1 - Payload
     */
    onSaveEditedMapping(state, { payload }) {
      const { id, mappingsFor, isAccordion, fieldKeys } = payload;

      if (state.selectedPreference) {
        const index = (
          isAccordion
            ? state.mapping.defaultMappings[mappingsFor][
                state.activeAccordionTitle[mappingsFor]
              ]
            : state.mapping.defaultMappings[mappingsFor]
        ).findIndex((mappedItem) => mappedItem.id === id);

        if (isAccordion) {
          // Clear entries
          fieldKeys.forEach((key) => {
            state.mapping.defaultMappings[mappingsFor][
              state.activeAccordionTitle[mappingsFor]
            ][index] = {
              ...state.mapping.defaultMappings[mappingsFor][
                state.activeAccordionTitle[mappingsFor]
              ][index],
              triggerApp: {
                ...state.mapping.defaultMappings[mappingsFor][
                  state.activeAccordionTitle[mappingsFor]
                ][index].triggerApp,
                [`${key}`]: [],
              },
            };
          });
          state.mapping.defaultMappings[mappingsFor][
            state.activeAccordionTitle[mappingsFor]
          ][index] = {
            ...state.mapping.defaultMappings[mappingsFor][
              state.activeAccordionTitle[mappingsFor]
            ][index],
            triggerApp: {
              ...state.mapping.defaultMappings[mappingsFor][
                state.activeAccordionTitle[mappingsFor]
              ][index].triggerApp,
              [`${state?.selectedPreference?.key}`]: [
                {
                  HRF: state.selectedPreference.HRF,
                  CRF: state.selectedPreference.CRF,
                  Type: state.selectedPreference?.type ?? "",
                  Description: state.selectedPreference?.description ?? "",
                  Accordion: state.selectedPreference?.accordion ?? "",
                },
              ],
            },
          };
        } else {
          // Clear entries
          fieldKeys.forEach((key) => {
            state.mapping.defaultMappings[mappingsFor][index] = {
              ...state.mapping.defaultMappings[mappingsFor][index],
              triggerApp: {
                ...state.mapping.defaultMappings[mappingsFor][index].triggerApp,
                [`${key}`]: [],
              },
            };
          });
          state.mapping.defaultMappings[mappingsFor][index] = {
            ...state.mapping.defaultMappings[mappingsFor][index],
            triggerApp: {
              ...state.mapping.defaultMappings[mappingsFor][index].triggerApp,
              [`${state?.selectedPreference?.key}`]: [
                {
                  HRF: state.selectedPreference.HRF,
                  CRF: state.selectedPreference.CRF,
                  Type: state.selectedPreference?.type ?? "",
                  Description: state.selectedPreference?.description ?? "",
                },
              ],
            },
          };
        }
      }

      state.selectedPreference = null;
      state.isEditMapping = false;
      state.currentlyEditing = null;
      state.isMappingSaved[mappingsFor] = false;
    },
    /**
     * Reducer updates the selected preference
     * @param state - Mapping Slice
     * @param param1 - Payload
     */
    setSelectedPreference(state, { payload }) {
      const { selectedOption, selectedKey } = payload;
      state.selectedPreference = { ...selectedOption, key: selectedKey };
    },
    /**
     * Reducer for canceling the mapping
     * @param state - Mapping slice
     */
    onCancelMapping(state) {
      state.currentlyEditing = null;
      state.isEditMapping = false;
      state.selectedPreference = null;
    },
    /**
     * Reducer check the action app fields that are not mapped and adds them in the mapping window
     * @param state - Mapping Slice
     * @param param1 - Payload
     */
    checkUnMappedTriggerFields(state, { payload }) {
      const { mappingsFor, actionAppObject, fieldKeys, isAccordion } = payload;

      const actionAppObjectKey = Object.keys(actionAppObject)[0];
      let defaultMappings = state.mapping.defaultMappings[mappingsFor];
      let newSet;

      // Create a new set of action app fields for accordions fields and for non-accordions fields
      if (isAccordion) {
        newSet = new Set();
        Object.keys(defaultMappings).forEach((accordion) => {
          if (defaultMappings[accordion].length > 0) {
            const mappedItems = defaultMappings[accordion].map(
              (mappedItem) => mappedItem.CRF
            );
            mappedItems.forEach((item) => newSet.add(item));
          }
        });
        const uniqueAccordions = new Set<string>();
        actionAppObject[actionAppObjectKey].forEach(
          (item: { accordion?: string }) => {
            const accordion = item.accordion;
            if (accordion) {
              uniqueAccordions.add(accordion);
            }
          }
        );
        uniqueAccordions.forEach((accordion) => {
          if (!defaultMappings[accordion]) {
            state.mapping.defaultMappings[mappingsFor][accordion] = [];
          }
        });
        defaultMappings = state.mapping.defaultMappings[mappingsFor];
        Object.keys(defaultMappings).map((accordion) => {
          const newMappings = defaultMappings[accordion];
          actionAppObject[actionAppObjectKey].forEach((field) => {
            if (!newSet.has(field.CRF) && accordion === field.accordion) {
              const newMappingItem: IMappingItem = {
                HRF: field.HRF,
                CRF: field.CRF,
                Type: field?.type ?? "",
                Description: field?.description ?? "",
                id: `${field.CRF}-${mappingsFor}`,
                Accordion: field.accordion,
                triggerApp: {},
              };
              fieldKeys.forEach((key) => (newMappingItem.triggerApp[key] = []));
              if (field.required) newMappingItem.Required = field.required;
              newMappings.push(newMappingItem);
            }
          });
          state.mapping.defaultMappings[mappingsFor][accordion] = newMappings;
        });
      } else {
        newSet = new Set(defaultMappings.map((mappedItem) => mappedItem.CRF));
        const newMappings = state.mapping.defaultMappings[mappingsFor];
        // Loop over action app fields which doesn't have accordions to make a new unmapped item
        actionAppObject[actionAppObjectKey].forEach((field) => {
          if (!newSet.has(field.CRF)) {
            const newMappingItem: IMappingItem = {
              HRF: field.HRF,
              CRF: field.CRF,
              Type: field?.type ?? "",
              Description: field?.description ?? "",
              id: `${field.CRF}-${mappingsFor}`,
              triggerApp: {},
            };
            fieldKeys.forEach((key) => (newMappingItem.triggerApp[key] = []));
            if (field.required) newMappingItem.Required = field.required;
            newMappings.push(newMappingItem);
          }
        });
        state.mapping.defaultMappings[mappingsFor] = newMappings;
      }
    },
    /**
     * Reducer checks the mapped trigger app fields and updates the deletedTriggerFields if any of the mapped fields are deleted
     * @param state - Mapping Slice
     * @param param1 - Payload
     */
    validateTriggerFields(state, { payload }) {
      const { mappingsFor, triggerAppObject, isAccordion } = payload;

      const newSet = {};
      const fieldsDeleted = {};
      // Create a set with trigger app fields
      Object.keys(triggerAppObject).forEach((fieldKey) => {
        fieldsDeleted[`${fieldKey}_${mappingsFor}`] = [];
        newSet[`${fieldKey}_${mappingsFor}`] = new Set(
          triggerAppObject[fieldKey].map((field) => field.CRF)
        );
      });

      if (isAccordion) {
        Object.keys(state.mapping.defaultMappings[mappingsFor]).forEach(
          (accordion) => {
            state.mapping.defaultMappings[mappingsFor][accordion].forEach(
              (mappedItem) => {
                if (
                  mappedItem?.triggerApp &&
                  Object.keys(mappedItem?.triggerApp).length > 0
                ) {
                  Object.keys(mappedItem?.triggerApp).forEach((key) => {
                    if (
                      mappedItem.triggerApp?.[key] &&
                      mappedItem.triggerApp[key].length > 0
                    ) {
                      mappedItem.triggerApp[key].forEach((field) => {
                        if (
                          newSet[`${key}_${mappingsFor}`] &&
                          !newSet[`${key}_${mappingsFor}`].has(field.CRF)
                        )
                          fieldsDeleted[`${key}_${mappingsFor}`].push(
                            field.CRF as string
                          );
                      });
                    }
                  });
                }
              }
            );
          }
        );
      } else {
        // Loop over mapping and find the deleted trigger app fields that are mapped
        state.mapping.defaultMappings[mappingsFor].forEach((mappedItem) => {
          if (
            mappedItem?.triggerApp &&
            Object.keys(mappedItem?.triggerApp).length > 0
          ) {
            Object.keys(mappedItem?.triggerApp).forEach((key) => {
              if (
                mappedItem.triggerApp?.[key] &&
                mappedItem.triggerApp[key].length > 0
              ) {
                mappedItem.triggerApp[key].forEach((field) => {
                  if (
                    newSet[`${key}_${mappingsFor}`] &&
                    !newSet[`${key}_${mappingsFor}`].has(field.CRF)
                  )
                    fieldsDeleted[`${key}_${mappingsFor}`].push(
                      field.CRF as string
                    );
                });
              }
            });
          }
        });
      }

      state.deletedTriggerFields = {
        ...state.deletedTriggerFields,
        ...fieldsDeleted,
      };
    },
    /**
     * Reducer adds multiple products to the mapping
     * @param state - Mapping Slice
     * @param param1 - Payload
     */
    onAddMultipleProducts(state, { payload }) {
      const { mappingsFor, selectedKey, mappedItem, selectedValue, checked } =
        payload;

      const index = state.mapping.defaultMappings[mappingsFor].findIndex(
        (mapping) => mapping.id === mappedItem.id
      );

      if (index !== -1) {
        if (checked === true) {
          state.mapping.defaultMappings[mappingsFor][index].triggerApp[
            selectedKey
          ].push({
            HRF: selectedValue.HRF,
            CRF: selectedValue.CRF,
            Type: selectedValue.type,
            Description: selectedValue.description,
          });
        } else {
          const itemIndex = state.mapping.defaultMappings[mappingsFor][
            index
          ].triggerApp[selectedKey].findIndex(
            (field) => field.CRF === selectedValue.CRF
          );

          if (itemIndex !== -1) {
            state.mapping.defaultMappings[mappingsFor][index].triggerApp[
              selectedKey
            ].splice(itemIndex, 1);
          }

          // Check if the array is empty, then assign an empty array
          if (
            state.mapping.defaultMappings[mappingsFor][index].triggerApp[
              selectedKey
            ].length === 0
          ) {
            state.mapping.defaultMappings[mappingsFor][index].triggerApp[
              selectedKey
            ] = [];
          }
        }

        state.isMappingSaved[mappingsFor] = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.pending, (state, action) => {
      if (!state.mapping.loading) {
        state.mapping.loading = true;
        state.mapping.currentRequestId = action.meta.requestId;
      }
    });
    builder.addCase(fetchStatus.fulfilled, (state, action) => {
      if (
        state.mapping.loading &&
        state.mapping.currentRequestId === action.meta.requestId
      ) {
        state.mapping.loading = false;
        state.mapping.currentRequestId = null;
        state.mapping.error = "";

        const { mappings, accordionFor, fields, fieldKeys } = action.payload;

        const dummyObject = {};

        Object.keys(mappings).forEach((key) => {
          if (
            accordionFor &&
            ((mappings[key].length === 0 && accordionFor[key]) ||
              (mappings[key].some((mapping) =>
                Object.prototype.hasOwnProperty.call(mapping, "Accordion")
              ) &&
                accordionFor[key]))
          ) {
            dummyObject[key] = {};
            if (mappings[key].length !== 0) {
              mappings[key].forEach((eachMapping) => {
                let { Accordion } = eachMapping;
                if (
                  !Accordion &&
                  (key === "customerSync" || key === "invoiceSync")
                ) {
                  const fieldKey =
                    key === "customerSync" ? fieldKeys[0] : fieldKeys[1];
                  const field =
                    fields &&
                    Object.keys(fields).length > 0 &&
                    fields[fieldKey].find(
                      (item) => item.CRF === eachMapping.CRF
                    );
                  if (field) {
                    Accordion = field.accordion;
                  }
                }
                const newMapping = {
                  ...eachMapping,
                  isChecked: false,
                  Accordion: Accordion,
                  id: `${eachMapping.CRF}-${key}`,
                };
                if (!dummyObject[key][Accordion]) {
                  dummyObject[key][Accordion] = [];
                }

                dummyObject[key][Accordion].push(newMapping);
                const accordionTitles = Object.keys(dummyObject[key]);

                state.activeAccordionTitle[key] = accordionTitles[0];
              });
            } else {
              const uniqueAccordions = new Set();
              if (fields && Object.keys(fields).length > 0) {
                Object.keys(fields).forEach((fieldKey) => {
                  fields[fieldKey].forEach((item) => {
                    if (item.accordion) {
                      uniqueAccordions.add(item.accordion);
                    }
                  });
                });
              }
              // Convert the set to an array and set the first accordion as active
              const uniqueAccordionArray = Array.from(
                uniqueAccordions
              ) as string[];
              state.activeAccordionTitle[key] = uniqueAccordionArray[0];
            }
          } else {
            dummyObject[key] = mappings[key].map((mapping) => ({
              ...mapping,
              isChecked: false,
              id: `${mapping.CRF}-${key}`,
            }));
          }
          state.isMappingSaved[key] = true;
        });
        state.mapping.defaultMappings = dummyObject;
      }
    });
    builder.addCase(fetchStatus.rejected, (state, action) => {
      if (
        state.mapping.loading &&
        state.mapping.currentRequestId === action.meta.requestId
      ) {
        state.mapping.loading = false;
        state.mapping.defaultMappings = {};
        state.mapping.currentRequestId = null;
        state.mapping.error = action.error.message ?? "Something went wrong";
      }
    });
  },
});

export const {
  checkUnMappedTriggerFields,
  validateTriggerFields,
  onSearchMappings,
  onEditMapping,
  onDeleteExistingMappings,
  onSaveEditedMapping,
  onCancelMapping,
  setSelectedPreference,
  updateIsMappingSaved,
  onAddMultipleProducts,
  updateItemOffset,
  updatePageCount,
  updateSelectedIndex,
  updateSlicedMappings,
  clearDefaultValues,
  storeActiveAccordion,
} = mappingSlice.actions;

export default mappingSlice.reducer;
